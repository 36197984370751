<template>
    <div class="evrotrust-signing-modal small">
        <bb-modal :visible="signingStatusPending" @close="close" flavor="user" class="m-t-50" staticOverlay>
            <p class="color-blue">{{$t('evrotrust.signing.pending.title')}}</p>
            <img class="p-y-20" src="/static/img/evrotrust_logo.png" alt="Evrotrust logo" width="80%"/>
            <p class="color-gray-80">{{$t('evrotrust.signing.pending.description1')}}</p>
            <div class="p-y-60" align="center">
                <bb-spinner/>
            </div>
            <bb-button display="link"
                       color="gray"
                       :label="$t('evrotrust.signing.pending.cancel')"
                       @click="close"/>
        </bb-modal>
        <bb-modal :visible="signingStatusCompleted" @close="close" flavor="user" class="m-t-50" staticOverlay>
            <bb-icon name="ui-check-circle" fill="mint" size="128"/>
            <h4 class="color-navy bold m-y-20">{{$t('evrotrust.signing.completed.title')}}</h4>
            <bb-button display="block"
                       class="m-t-20"
                       color="mint"
                       :label="$t('evrotrust.signing.completed.continue')"
                       @click="close"/>
        </bb-modal>
        <bb-modal :visible="signingStatusFailed" @close="close" flavor="user" class="m-t-50" staticOverlay>
            <p class="color-blue">{{$t('evrotrust.signing.failed.title')}}</p>
            <bb-icon name="ui-alert-circle-outline" fill="gray-40" size="64" class="m-y-20"/>
            <p class="color-red">{{signingErrorMessage}}</p>
            <bb-button display="block"
                       class="m-t-20"
                       color="mint"
                       :label="$t('evrotrust.signing.failed.retry')"
                       @click="initiateSigningSession"/>
        </bb-modal>
    </div>
</template>

<script>
    import { BbButton, BbIcon, BbModal, BbSpinner } from '@bigbank/interface-components'
    import '@bigbank/interface-components/dist/svg/ui/check-circle'
    import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline'
    import { mapActions, mapGetters } from 'vuex'
    import RetryHelper from '../../util/RetryHelper'

    const SIGNING_STATUS_PENDING = 'PENDING'
    const SIGNING_STATUS_COMPLETED = 'COMPLETED'
    const SIGNING_STATUS_FAILED = 'FAILED'

    export default {
        name: 'EvrotrustSigningModal',

        data() {
            return {
                retryHelper: null,
                closeTimeoutHandler: null,
                signingStatus: SIGNING_STATUS_PENDING,
                signingError: null,
            }
        },

        created() {
            this.initiateSigningSession()
        },

        computed: {
            ...mapGetters('processStep', ['externalId', 'application']),
            signingStatusPending() {
                return this.signingStatus === SIGNING_STATUS_PENDING
            },
            signingStatusCompleted() {
                return this.signingStatus === SIGNING_STATUS_COMPLETED
            },
            signingStatusFailed() {
                return this.signingStatus === SIGNING_STATUS_FAILED
            },
            signingErrorMessage() {
                const translationKey = 'evrotrust.signing.failed.error.' + this.signingError

                return this.$te(translationKey)
                    ? this.$t(translationKey)
                    : this.$t('evrotrust.signing.failed.error.default')
            },
        },

        methods: {
            ...mapActions('signing', ['initiateSigning', 'getSigningStatus']),
            initiateSigningSession() {
                this.signingError = null
                this.signingStatus = SIGNING_STATUS_PENDING
                this.initiateSigning({
                    externalId: this.externalId,
                    body: {
                        method: 'EVROTRUST',
                        displayText: this.$t('evrotrust.signing.displayText'),
                        withoutBigbankSignature: this.application.countrySpecificProcessData?.bg?.useBoricaForBankSignature || false,
                    },
                }).then(async () => {
                    this.retryHelper = new RetryHelper(this.pollSigningStatus, 60, 5000)
                    const signingStatusResponse = await this.retryHelper.execute()

                    if (signingStatusResponse.state === 'OK') {
                        this.signingStatus = SIGNING_STATUS_COMPLETED
                        this.close()
                    } else {
                        this.signingError = signingStatusResponse.state
                        this.signingStatus = SIGNING_STATUS_FAILED
                    }
                }).catch(error => {
                    this.signingError = error?.response?.data?.code
                    this.signingStatus = SIGNING_STATUS_FAILED
                })
            },
            close() {
                if (this.closeTimeoutHandler) {
                    clearTimeout(this.closeTimeoutHandler)
                    this.closeTimeoutHandler = null
                }
                if (this.retryHelper) {
                    this.retryHelper.cancel()
                    this.retryHelper = null
                }
                if (this.signingStatusCompleted) {
                    this.$emit('signingCompleted')
                } else {
                    this.$emit('signingCancelled')
                }
            },
            pollSigningStatus() {
                return this.getSigningStatus({
                    externalId: this.externalId,
                    body: {
                        method: 'EVROTRUST',
                    },
                }).then(response => {
                    if (response.data.status === 'COMPLETED_OK' || response.data.status === 'COMPLETED_FAILURE') {
                        return Promise.resolve(response.data)
                    }

                    return Promise.reject(response.data)
                })
            },
        },

        components: {
            BbModal,
            BbSpinner,
            BbButton,
            BbIcon,
        },
    }
</script>

<style scoped lang="scss">
  .evrotrust-signing-modal {
    text-align: center;
  }
</style>
